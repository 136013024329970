import React from 'react';

import CharlesMeta from 'components/common/meta/CharlesMeta';
import StartOther from 'components/Start/StartOther';

const StartPage = () => (
  <>
    <CharlesMeta
      data={{
        title:
                    'Consultation en ligne avec une sexologue | Mia',
        description:
                    'Commencez votre consultation en ligne et traitez tous vos problèmes sexuels.',
      }}
    />
    <StartOther />
  </>
);

export default StartPage;
